import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerPresupuesto() {
    var url = ConfigAPI.baseURL + "budgets" + Session.getToken();
    return instance.get(url);
  },
  mostrarPresupuesto(id) {
    var url = ConfigAPI.baseURL + "budgets/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPresupuesto(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "budgets" + Session.getToken();
    return instance.post(url,params);
  },
  editarPresupuesto(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "budgets/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarPresupuesto(id) {
    var url = ConfigAPI.baseURL + "budgets/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarPresupuesto(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "budgets/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  

  obtenerVoucherById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/id" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerVoucherByCustomer(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/customer" + Session.getToken();
    return instance.post(url,params);
  },  
  obtenerVoucherTotalsTemporary(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/totals-temporary" + Session.getToken();
    return instance.post(url,params);
  },  
  
  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },
  obtenerVendedores() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  },   
  obtenerPuntosVentas() {
    var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
    return instance.get(url);
  },     
}

export default servicesAPI;
